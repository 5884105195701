import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Fetch Suitability Status Template
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchSuitabilityStatusTemplate = (corporationId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/csr/suitability_status_template`))
}

/**
 * Import suitability status
 * @param {String} corporationId
 * @param {Object} formData
 * @return {Promise}
 */
export const importSuitabilityStatus = (corporationId, formData) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/csr/suitability_status_import`), formData)
}
